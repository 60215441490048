<template>
    <div class="contentBorderTow_container">
        {{content}}
    <div class="contentBorderTow_iconBlock contentBorderTow_iconBlock_left_top">
        <div class="contentBorderTow_point"></div>
        <div class="contentBorderTow_icon">
            <img :src="require(`@/assets/images/meeting/${iconName}`)" />
        </div>
        <div class="contentBorderTow_point"></div>
    </div>
    <div class="contentBorderTow_iconBlock contentBorderTow_iconBlock_right_bottom">
        <div class="contentBorderTow_point"></div>
        <div class="contentBorderTow_icon">
            <img :src="require(`@/assets/images/meeting/${iconName}`)" />
        </div>
        <div class="contentBorderTow_point"></div>
    </div>
    </div>
</template>
<script>
export default {
    name:"contentBorderTow",
    props:{
        iconName:{
            type:String,
            default:""
        },
        content:{
            type:String,
            default:""
        }
    },
    data(){
        return {
            data:{
                
            }
        }
    }
}
</script>
<style>
.contentBorderTow_container{
    position: relative;
    width: 100%;
    border-radius: 1vw;
    border: 1px solid #80b25f;
    padding: 2.8vw;
}

.contentBorderTow_iconBlock{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(252, 245, 248 0);
    z-index: 100;
    width: 8vw;
}
.contentBorderTow_iconBlock_left_top{
    position: absolute;
    top: -2.1vw;
    left: 2.8vw;
    background:linear-gradient(#dff1bb,#dff1bb);
}
.contentBorderTow_iconBlock_right_bottom{
    position: absolute;
    bottom: -2.1vw;
    right: 2.8vw;
    background:linear-gradient(#dff1bb,#dff1bb);
}
.contentBorderTow_icon{
    height: 4vw;
    width: 3vw;
}
.contentBorderTow_icon img{
    width: 100%;
    height: 100%;
}
.contentBorderTow_point{
    background-color: #80b25f;
    width: 1.6vw;
    height: 1.6vw;
    border-radius: 0.8vw;
}
</style>